import React, { useState, useEffect } from 'react';

import Link from '../atoms/link';
import Image from '../atoms/image';
import { H1, H2, H3 } from '../atoms/header';
import { UL } from '../atoms/definitionList';
import { FeaturedHeader } from '../molecules/header';
import { P } from '../atoms/paragraph';
import { createMarkup } from '../../../utils';

export const HeroContainer = ({ contentHeader, contentFeatured, fullWidthCtas = false, ...props }) => {
  return (contentHeader && (
    <section className="hero-container">
      {
        contentHeader.title && <H1>{contentHeader.title}</H1>
      }
      {
        !!contentHeader.excerpt && !!contentHeader.excerpt.length &&
        <div className={`hero-container__content-wrapper`}>
          {
            contentHeader.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt">{paragraph}</P>
            ))
          }
        </div>
      }
      {
        contentHeader.ctas && contentHeader.ctas.map(({ to, href, icon, label, ...link }, index) => (
          <Link key={`${+new Date()}-${index}--link-cta`} icon={icon} className={`link--cta hero-container-link--cta`} to={to || href} {...link}>{label}</Link>
        ))
      }
      <FeaturedHeader content={contentFeatured} fullWidthCtas={fullWidthCtas} />
    </section>
  )) || (<></>);
};

export const ContentContainer = ({ content, imageStyle, isSectionTitle = false, isTileItem = false, isFeaturedContent = false, isImageNoMargin = false, theme, isButtonBlock, direction, hasBackground, className = '', noGrow = false, ...props }) => {
  const isInvisibleClass = content && content.image && content.image.isInvisible ? 'link--btn-block' : '';
  const isImageNoMarginClass = isImageNoMargin ? 'no-margin' : '';
  const imageClass = 'featured-header__image';
  const themeClass = theme === 'dark' ? ' content__container--dark' : '';
  const growClass = noGrow ? ' content__container--no-grow' : '';
  const backgroundClass = hasBackground ? 'has-background' : '';
  const containerBackgroundClass = hasBackground ? ` content__container--${backgroundClass}` : '';
  const featuredContentClass = isFeaturedContent ? ` content__container--featured` : '';
  const ctaBackgroundClass = hasBackground || isTileItem ? ` link--block` : '';
  const buttonBlockClass = isButtonBlock ? ` button__block` : ``;
  const titleType = isSectionTitle ? 'section' : '';
  return (content && (
    <section className={`content__container ${growClass}${themeClass}${buttonBlockClass}${containerBackgroundClass}${featuredContentClass}${isImageNoMarginClass} ${className}`} direction={direction}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image ${isInvisibleClass}`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className={imageClass}
                    imageStyle={imageStyle}
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                imageStyle={imageStyle}
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className={imageClass}
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
      {
        content.video &&
        (
          content.video.href ?
            (
              <>
                <Link
                  className={`link--video ${isInvisibleClass}`}
                  to={content.video.href}
                  target={content.video.target}
                  label={content.video.title}
                  title={content.video.title}>
                  <iframe
                    src={content.video.src}
                    alt={content.video.title}
                    title={content.video.title}
                    className="featured-header__video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
                  {
                    content.video.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <iframe
                src={content.video.src}
                alt={content.video.title}
                title={content.video.title}
                className="featured-header__video"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen />
            )
        )
      }
      <div className="content__container-group" style={{ ...(props.groupStyle ? props.groupStyle : {}) }}>
        {
          content.tags && content.tags.length > 0 ?
            <div className="tags__wrapper">
              {
                content.tags.map((tagItem, index) => (
                  <span className="tag-item" key={`tag-${index}`} dangerouslySetInnerHTML={{ __html: tagItem }} />
                ))
              }
            </div>
            :
            <></>
        }
        {
          content.title && <H1 type={titleType}>{content.title}</H1>
        }
        {
          content.title2 && <H2 type={titleType}>{content.title2}</H2>
        }
        {
          content.title3 && <H3 type={titleType}>{content.title3}</H3>
        }
        {
          content.excerpt &&
          <div className="content__body">
            {
              content.excerpt && content.excerpt.map((paragraph, index) => (
                <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt">{paragraph}</P>
              ))
            }
          </div>
        }
        {
          content.sections && content.sections.length > 0 &&
          <div className="content__body">
            {
              content.sections && content.sections.map((section, index) => (
                <div className="content__section" key={`${+new Date()}-${index}--content-section`}>
                  {
                    section.title && <H2>{section.title}</H2>
                  }
                  {
                    section.excerpt && <Excerpt content={section.excerpt} child={true}></Excerpt>

                  }
                </div>
              ))
            }
          </div>
        }
        {
          content.list && (
            <UL list={content.list}></UL>
          )
        }
        {
          content.links && content.links.map(({ label, href, ...itemProps }, index) => (
            <Link key={`${+new Date()}-${index}--link-link`} className="link--line" to={href} label={label} title={label} {...itemProps}>{label}</Link>
          ))
        }
        {
          content.ctas && content.ctas.map(({ label, href, ...itemProps }, index) => (
            <Link key={`${+new Date()}-${index}--link-cta`} className={`link--cta ${ctaBackgroundClass}`} to={href} label={label} title={label} {...itemProps}>{label}</Link>
          ))
        }
      </div>
    </section >
  )) || (<></>);
};

export const Tile = ({ content, direction = 'col', theme, ...props }) => {
  const tileDirectionClassName = direction === 'row' ? 'tile__wrapper--horizontal' : '';
  return (
    <div className={`tile__wrapper ${tileDirectionClassName}`}>
      {
        content.image &&
        (
          content.image.href ?
            (
              <>
                <Link
                  className={`link--image`}
                  to={content.image.href}
                  target={content.image.target}
                  label={content.image.title}
                  title={content.image.title}>
                  <Image
                    src={content.image.src}
                    alt={content.image.title}
                    title={content.image.title}
                    isInvisible={content.image.isInvisible}
                    className="tile__image"
                    caption={content.image.caption} />
                  {
                    content.image.isInvisible && content.label && <H2 className={`link--btn-block-header`}>{content.label}</H2>
                  }
                </Link>
              </>
            ) : (
              <Image
                src={content.image.src}
                alt={content.image.title}
                title={content.image.title}
                className="tile__image"
                isInvisible={props.isInvisible}
                caption={content.image.caption} />
            )
        )
      }
      <div className="tile__content">
        {
          content.title && <H3>{content.title}</H3>
        }
        {
          content.excerpt &&
          <div className="content__body">
            {
              content.excerpt && content.excerpt.map((paragraph, index) => (
                <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt">{paragraph}</P>
              ))
            }
          </div>
        }
      </div>
    </div>
  );
};

export const ContentWrapper = ({ children, theme, isSticky, className, ...props }) => {
  const themeClass = theme === 'dark' ? ' content__container--dark' : '';
  const stickyClass = isSticky ? ' content__container--sticky' : '';
  return (
    <section className={`content__container${themeClass}${stickyClass} ${className}`}>
      {children}
    </section>
  );
};

export const Carousel = ({ children, content, activeIndex: propActiveIndex = 0, ...props }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(() => (propActiveIndex));
  }, []);

  function setIndex(event, index) {
    !!event && event.preventDefault();
    if (index < 0) index = content.length - 1;
    if (index > content.length - 1) index = 0;
    setActiveIndex(() => (index));
  }
  return (
    <section className={`carousel__wrapper`}>
      {
        content && content.map((item, index) => {
          return (
            <ContentContainer content={item} key={`testimonial-index-${index}`} className={activeIndex === index ? 'content__container--active' : ''} />
          )
        })
      }
      <div className="carousel__controls">
        <a href="#" onClick={(e) => setIndex(e, activeIndex - 1)}>
          <div className="carousel__controls-el">
            <i className="app-icon" icon={'navigate_before'} />
          </div>
        </a>
        <a href="#" onClick={(e) => setIndex(e, activeIndex + 1)}>
          <div className="carousel__controls-el">
            <i className="app-icon" icon={'navigate_next'} />
          </div>
        </a>
      </div>
      <div className="carousel__nav">
        {
          content && content.map((item, index) => {
            return (<a href="#" key={`carousel-nav-index--${index}`} className={activeIndex === index ? 'carousel__nav-dot carousel__nav-dot--active' : 'carousel__nav-dot'} onClick={(e) => setIndex(e, index)}></a>)
          })
        }
      </div>
    </section>
  );
};

export const Excerpt = ({ content, child = false, section = false }) => {
  return (
    <div className={`content__body ${section ? 'content__section' : ''}`}>
      {
        content && content.map((paragraph, index) => (
          <React.Fragment key={`${+new Date()}-${index}--content-excerpt`}>
            {
              typeof paragraph === 'string' ?
                <P className="content--excerpt">{paragraph}</P>
                :
                <>
                  {
                    paragraph.title && !child ? <H2>{paragraph.title}</H2> : <H3>{paragraph.title}</H3>
                  }
                  {
                    paragraph.excerpt && <Excerpt content={paragraph.excerpt} child={true}></Excerpt>
                  }
                  {
                    paragraph.list && <UL key={`${+new Date()}-${index}--content-excerpt`} list={paragraph.list}></UL>
                  }
                  {
                    paragraph.footer && <Excerpt content={paragraph.footer} child={true}></Excerpt>
                  }
                </>
            }
          </React.Fragment>
        ))
      }
    </div>
  );
};