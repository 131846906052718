import React from 'react';
import { navigate, withPrefix } from 'gatsby';

import { go } from '../../../utils';

const Button = ({ to, text, icon, background, isBlock = false, target, className, ...props }) => {
  const backgroundClass =
    background === 'transparent' ? ' button--cta-transparent' :
      background === 'alert' ? ' button--cta-alert' : ''
    ;
  const iconClass = icon ? ' button--has-icon' : '';
  const blockClass = isBlock ? ' button--fullwidth' : '';
  const eventHandler = (url, target) => {
    const isExternalTarget = url.indexOf('http') === 0 ? true : false;
    return target === "_blank" || isExternalTarget ? go(url) : navigate(withPrefix(url));
  };
  return (
    <button text={`${text}`} className={`button button--cta ${className || ''}${iconClass}${blockClass}${backgroundClass}`} onClick={event => { eventHandler(to, target); }} icon={icon} {...props} >{text}</button>
  );
};

export default Button;
