import React, { useState } from 'react';

import Image from '../atoms/image';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { Caption } from '../molecules/caption';
import { ContentContainer } from '../molecules/container';

export const FeaturedHeader = ({ content, ...props }) => {
  const [activeHeadlineIndex, setActiveHeadlineIndex] = useState(0);
  return (
    <div className="featured-header__wrapper">
      {
        content.headlines.map((item, index) => {
          return <div className={`featured-header__item ${activeHeadlineIndex === index ? 'featured-header__item--active' : ''}`} key={index}>
            <Image
              src={item.image.src}
              alt={item.image.title}
              title={item.image.title}
              className="featured-header__image"
              caption={item.image.caption} />
            <Caption content={item} {...props} />
          </div>
        })
      }
      <div className="featured-header__navigation">
        <ul>
          {content.headlines.map((_, index) => <li key={index} className={index === activeHeadlineIndex ? 'active' : ''}>
            <a href="#" onClick={(e) => { e.preventDefault(); setActiveHeadlineIndex(index); }}></a>
          </li>)}
        </ul>
      </div>
      {
        content.items?.length && <Grid gap={20} noPadding={false}>
          <GridRow direction="row" className="events__list events__list--square-thumbs">
            {
              [...content.items].map((caseItem, index) => (
                <GridCol colSize={(12 / content.items.length) - 1} key={index} className="events__list-item">
                  <ContentContainer content={caseItem} direction="column" />
                </GridCol>
              ))
            }
          </GridRow>
        </Grid>
      }
    </div>
  );
};

const Header = ({ children, ...props }) => (
  <header {...props}>
    {children}
  </header>
);

export default Header;
