import React from 'react';
import { createMarkup } from '../../../utils';

import { H1 } from '../atoms/header';
import { P } from '../atoms/paragraph';
import Link from '../atoms/link';

export const Caption = ({ content, fullWidthCtas, ...props }) => {
  return (
    (content &&
      <div className="caption__wrapper">
        <H1>{content.title}</H1>
        <div className="caption__excerpt-wrapper">
          {
            content.excerpt.map((paragraph, index) => (
              <P key={`${+new Date()}-${index}--content-excerpt`} className="content--excerpt">{paragraph}</P>
            ))
          }
        </div>
        <div className="caption__cta-wrapper">
          {
            content.ctas.map(({label, to, ...itemProps}, index) => (
              <Link key={`${+new Date()}-${index}--link-cta`} to={to || itemProps.href} fullWidthCtas={fullWidthCtas} label={label} type="button" {...itemProps}>{label}</Link>
            ))
          }
        </div>
      </div>
    ) || <>ERROR</>
  );
};