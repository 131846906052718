import React from 'react';
import Link from '../atoms/link';

import Button from '../atoms/button';

import { fixMainNavigationItemLink, getPath } from '../../../utils';

const NavigationItem = ({ item, index }) => {
  return (
    <li key={`${+new Date()}-${index}`} className={`main-navigation--menu-list-item ${item.children && item.children.length > 0 ? 'main-navigation--has-submenu-items' : ''}`}>
      {
        !item.children
          ?
          <Link
            item={item}
            to={fixMainNavigationItemLink(item.href, getPath()) || '/'}
            {...item}
          >{item.label}</Link>
          : (
            <>
              <a href="#main-menu-item" onClick={e => e.preventDefault()}>{item.label}</a>
              <NavigationSubmenuItem items={item.children} parentIndex={`${index}`} />
            </>
          )
      }
    </li>
  );
};

const NavigationSubmenuItem = ({ items, parentIndex }) => {
  return (
    <ul className="main-navigation--submenu-list">
      {
        items && items.map((item, index) => (
          <li key={`${+new Date()}-${parentIndex}-${index}`} className={`main-navigation--submenu-item ${item.children && item.children.length > 0 ? 'main-navigation--has-submenu-items' : ''}`}>
            {
              !item.children
                ? (
                  <>
                    <Link
                      {...item}
                      item={item}
                      to={item.href || '/'}
                    >{item.label}</Link>
                    {item.description && item.description && <span className="main-navigation--submenu-item-description">{item.description}</span>}
                  </>
                )
                : (
                  <>
                    <Link
                      {...item}
                      item={item}
                      to={item.href || '/'}
                    >{item.label}</Link>
                    {item.description && item.description && <span className="main-navigation--submenu-item-description">{item.description}</span>}
                    <NavigationSubmenuItem items={item.children} parentIndex={`${index}-${parentIndex}`} />
                  </>
                )
            }
          </li>
        ))
      }
    </ul>
  );
};

const Navigation = ({ links, className }) => (
  <nav className={`main-navigation--menu-wrapper${!!className?` `+className: ``}`}>
    {
      links && links.items && links.items.length > 0 &&
      <ul className="main-navigation--menu-list">
        {
          links.items.map((item, index) => {
            return <NavigationItem item={item} index={index} key={`${+new Date()}-${index}`} />;
          })
        }
      </ul>
    }
    {
      links && links.ctas && <div className="cta__wrapper">
        {
          links.ctas.map(({ href, label, icon, type, ...rest }, index) => <Button key={`nav-item-${+new Date()}--${index}`} to={href} icon={icon} text={label} {...rest} />)
        }
      </div>
    }
  </nav>
);

export default Navigation;
