import React from 'react';

export const InputText = ({ data, name = `inputName_${+new Date()}`, onChange, ...props }) => {
  return (
    <input
      className="input"
      name={name}
      type={data.type || "text"}
      placeholder={data.info || data.placeholder}
      onChange={e => onChange({ [name]: e.target.value })}
      {...props}
    />
  );
};

export const InputTextarea = ({ data, name = `inputName_${+new Date()}`, onChange, value, ...props }) => {
  return (
    <textarea
      className="input"
      name={name}
      type={data.type || "text"}
      placeholder={data.info || data.placeholder}
      onChange={e => onChange({ [name]: e.target.value })}
      {...props}
      defaultValue={value}
    />
  );
};

export const InputRadio = ({ data, name = `inputName_${+new Date()}`, onChange, options, value, ...props }) => {
  return (
    <div className={`radio-group__wrapper`}>
      {
        data.options.map((option, index) => {
          return (
            <div className={`radio-group__option`} key={`radio_${name}__${index}`}>
              <label htmlFor={`${name}_${index}`}>{option.label}</label>
              {option.info && <span className='radio-group__option-info'>{option.info}</span>}
              <input
                id={`${name}_${index}`}
                name={name}
                type={data.type || "text"}
                placeholder={data.placeholder}
                checked={option.value === value}
                onChange={e => onChange({ [name]: e.target.value })}
                value={option.value}
                {...props}
              />
            </div>
          )
        })
      }
    </div>
  );
};

export const InputCheckbox = ({ data, name = `inputName_${+new Date()}`, onChange, options, value, ...props }) => {
  return (
    <div className={`checkbox-group__wrapper`}>
      {
        data.options.map((option, index) => {
          return (
            <div className={`checkbox-group__option`} key={`radio_${name}__${index}`}>
              <label htmlFor={`${name}_${index}`}>{option.label}</label>
              {option.info && <span className='checkbox-group__option-info'>{option.info}</span>}
              <input
                id={`${name}_${index}`}
                name={name}
                type={data.type || "text"}
                placeholder={data.placeholder}
                checked={value.includes(option.value)}
                onChange={e => {
                  const elementValue = !value.includes(option.value) ? [
                    ...value,
                    e.target.value
                  ] : value.filter(values => values !== e.target.value);
                  onChange({ [name]: elementValue })
                }}
                value={option.value}
                {...props}
              />
            </div>
          )
        })
      }
    </div>
  );
};

export const InputSelect = ({ data, name = `inputName_${+new Date()}`, onChange, options, value, ...props }) => {
  return (
    <div className={`select-group__wrapper`}>
      {
        <select
          name={name}
          {...props}
          onChange={e => {
            onChange({ [name]: [...e.target.selectedOptions].map(option => option.value) })
          }}
          onBlur={(e) => e}
          value={value}
        >
          {
            data.options.map((option, index) => {
              return (
                <option
                  key={`radio_${name}__${index}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            })
          }
        </select>
      }
    </div>
  );
};