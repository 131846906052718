import React from 'react';

import { H1 } from '../atoms/header';
import { P } from '../atoms/paragraph';
import Button from '../atoms/button';

export const SpotlightContent = ({ content, ...rest }) => {
  // console.log(content.excerpt, rest);
  return (
    (
      content &&
      (
        <div className="spotlight__wrapper">
          <H1>{content.title}</H1>
          {
            !!content.excerpt && content.excerpt.map((Pitem, index) => (
              <P key={`spotlight-content-p-${index}`}>{Pitem}</P>
            ))
          }
          {
            content.ctas.map((Aitem, index) => (
              <Button to={Aitem.href} icon={Aitem.icon ? Aitem.icon : ''} text={Aitem.label} key={`spotlight-content-p-${index}`} />
            ))
          }
        </div>
      )
    ) || null
  );
};