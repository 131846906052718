import React, { useEffect } from 'react';

import { scrollHandler, detachEventListeners, GAnalytics, isBrowser } from '../../../utils';

import { mainNavigationScrollHandler } from '../organisms/mainNavigation';

export default ({ googleAnalyticsId, ...props}) => {

  useEffect(() => {
    if (!isBrowser) return;
    scrollHandler(window, mainNavigationScrollHandler);
  }, []);

  useEffect(() => {
    if (!isBrowser) return;
    detachEventListeners([
      { target: window, event: 'scroll', callback: mainNavigationScrollHandler }
    ]);
  }, []);

  return (
    <>
      <>{props.children}</>
      {
        !!googleAnalyticsId && <GAnalytics id={googleAnalyticsId} />
      }
    </>
  );
};