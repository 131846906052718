import React, { useEffect } from 'react';

import { isBrowser } from '../generic/common';

const initGA = (id) => {
  if (isBrowser() && !!id) {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', id);
  }
};

export const GAnalytics = ({ id }) => {
  useEffect(() => {
    if (isBrowser()) {
      initGA(id);
    }
  }, []);

  return (
    <></>
  );
};