import React from 'react';

export const Devider = ({margin = 4, bottom = true, top = true}) => {
  const bottomClass = bottom ? ` devider-${margin}` : ` devider-${margin}--no-bottom`;
  const topClass = top ? ` devider-${margin}` : ` devider-${margin}--no-top`;
  return (
    <section className={`devider devider-${margin}${bottomClass}${topClass}`} />
  );
};

export const Spacer = ({spacing = 0, bottom = true, top = true, children}) => {
  const bottomClass = bottom ? ` devider--spacer-${spacing}` : ` devider--spacer-${spacing}--no-bottom`;
  const topClass = top ? ` devider--spacer-${spacing}` : ` devider--spacer-${spacing}--no-top`;
  return (
    <div className={`spacer devider--spacer-${spacing}${bottomClass}${topClass}`}>{children}</div>
  );
};