import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCurrentPageProps, getPath, getPageHash, scrollToHash } from '../../../utils';

import '../../../styles/index.scss';

import HOC from '../organisms/root';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { HeroContainer, ContentContainer, ContentWrapper, Tile, Carousel } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import { SpotlightContent } from '../molecules/spotlight';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { H1, H2, H3 } from '../atoms/header';
import Address from '../atoms/address';
import { DefinitionList } from '../atoms/definitionList';
import CookiesTooltip from '../atoms/cookiesTooltip';
import Link from '../atoms/link';
import Image from '../atoms/image';

class Layout extends Component {

  componentDidMount() {
    setTimeout(() => !!getPageHash(getPath()) && scrollToHash(getPageHash(getPath())), 100);
  }

  render() {
    const { children, data } = this.props;
    return data ? (
      <HOC
        googleAnalyticsId={data?.siteConfigs?.tracking?.ga?.id}>
        {children}
        <MainNavigation navLinks={data.mainNavLinks} pageType="main" />
        <Header>
          <HeroContainer
            contentHeader={getCurrentPageProps(data.heroHeaders, getPath())}
            contentFeatured={getCurrentPageProps(data.heroFeatured, getPath())} />
          <div className="logo-icon" />
        </Header>
        <Spacer spacing={2} />
        <Grid gap={16}>
          <GridRow direction="row">
            <GridCol colSize={5}>
              <Grid noPadding={true}>
                <GridRow direction="column">
                  <Spacer spacing={12} />
                  <GridCol>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "spotlightDetails")} />
                  </GridCol>
                </GridRow>
              </Grid>
            </GridCol>
            <GridCol colSize={5} stretched={true}>
              <SpotlightContent content={getCurrentPageProps(data.spotlightContent, getPath())} />
            </GridCol>
          </GridRow>
          <Devider margin={10} />
          <GridRow direction="row">
            <GridCol colSize={6}>
              <a anchor='about-us'></a>
              <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "aboutUs")} />
            </GridCol>
            <GridCol colSize={6}>
              <Grid noPadding={true}>
                <GridRow direction="column">
                  <GridCol>
                    <a anchor='mission'></a>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "mission")} />
                  </GridCol>
                </GridRow>
                <GridRow direction="column">
                  <GridCol>
                    <a anchor='vision'></a>
                    <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "vision")} />
                  </GridCol>
                </GridRow>
              </Grid>
            </GridCol>
          </GridRow>
        </Grid>
        {
          <>
            <Spacer spacing={10} bottom={false} />
            <ContentContainer isFeaturedContent={true} content={data.featuredContent} />
            <Spacer spacing={10} top={true} />
          </>
        }
        <a anchor='scholing'></a>
        {
          data.education &&
          <Grid gap={10} noPadding={false}>
            <H1 type="section">{data.education.header.title}</H1>
            <GridRow direction="row">
              <GridCol colSize={6}>
                <ContentContainer content={data.education.intro} hasBackground={true} />
              </GridCol>
              <GridCol colSize={6} direction="column" className="events__list events__list--square-thumbs">
                <H3 style={{ marginTop: 0 }} type="section">{data.education.section_1.title}</H3>
                {
                  [...data.education.section_1.items].map((caseItem, index) => (
                    <GridCol key={index} className="events__list-item">
                      <ContentContainer content={caseItem} direction="row" />
                    </GridCol>
                  ))
                }
              </GridCol>
            </GridRow>
            <GridRow direction="row">
              <GridCol colSize={6} direction="column" className="events__list events__list--square-thumbs">
                <H3 style={{ marginTop: 0 }} type="section">{data.education.section_2.title}</H3>
                {
                  [...data.education.section_2.items].map((caseItem, index) => (
                    <GridCol key={index} className="events__list-item">
                      <ContentContainer content={caseItem} direction="column" noMargin={true} groupStyle={{ marginLeft: 0 }} imageStyle={{ aspectRatio: '16/9' }} />
                    </GridCol>
                  ))
                }
              </GridCol>
              <GridCol colSize={6} direction="column" className="events__list events__list--square-thumbs">
                <H3 style={{ marginTop: 0 }} type="section">{data.education.section_3.title}</H3>
                {
                  [...data.education.section_3.items].map((caseItem, index) => (
                    <GridCol key={index} className="events__list-item">
                      <ContentContainer content={caseItem} direction="row" />
                    </GridCol>
                  ))
                }
              </GridCol>
            </GridRow>
          </Grid>
        }
        <div className="logo-icon logo-icon--large logo-icon--y-mid" />
        {
          data.research &&
          <Grid gap={10} noPadding={false}>
            <Devider margin={10} />
            <a anchor='leernetwerken'></a>
            <H1 type="section">{data.research.title}</H1>
            <GridRow direction="row" isWrapped={true}>
              {
                data.research.items.map((item, index) => {
                  return (
                    <GridCol colSize={6} key={`research-item-${index}`} noMargin={true}>
                      <ContentContainer content={item} />
                    </GridCol>
                  )
                })
              }
            </GridRow>
            <GridRow className="actions-grid">
              <GridRow direction="row">
                <GridCol colSize={10}>
                  <H3 type="section">{data.research.subjects.title}</H3>
                  {
                    !!data.research.subjects.items &&
                    <GridRow direction="row" isWrapped={true} isTile={true} >
                      {
                        data.research.subjects.items.map((caseItem, index) => (
                          <GridCol colSize={6} key={index}>
                            <ContentContainer content={caseItem} isTileItem={true} />
                          </GridCol>
                        ))
                      }
                    </GridRow>
                  }
                </GridCol>
                <GridCol colSize={2} className="actions-grid__ctas-wrapper">
                  {
                    !!data.research.subjects.ctas && data.research.subjects.ctas.map((caseItem, index) => (
                      <ContentContainer key={`research-subject-index-${index}`} content={caseItem} isTileItem={true} />
                    ))
                  }
                </GridCol>
              </GridRow>
            </GridRow>
          </Grid>
        }
        {
          data.academicLearning &&
          <Grid gap={10} noPadding={false}>
            <Devider margin={0} />
            <Spacer spacing={13} bottom={true} />
            <H1 type="section">{data.academicLearning.title}</H1>
            <GridRow direction="row">
              <GridCol colSize={5}>
                <ContentContainer content={data.academicLearning.next} hasBackground={true} />
              </GridCol>
              <GridCol colSize={7} direction="column" className="events__list events__list--square-thumbs events__list--normal-font">
                {
                  [...data.academicLearning.items].map((caseItem, index) => (
                    <GridCol key={index} className="events__list-item">
                      <ContentContainer content={caseItem} direction="row" />
                    </GridCol>
                  ))
                }
              </GridCol>
            </GridRow>
          </Grid>
        }
        <a anchor='onderwijsconferenties'></a>
        {
          data.onderwijsconferenties &&
          <Grid gap={10} noPadding={false}>
            <H1 type="section">{data.onderwijsconferenties.title}</H1>
            <GridRow direction="row">
              <GridCol colSize={6}>
                <ContentContainer content={data.onderwijsconferenties.next} />
              </GridCol>
              <GridCol colSize={6} direction="column" className="events__list events__list--square-thumbs">
                {
                  [...data.onderwijsconferenties.items].map((caseItem, index) => (
                    <GridCol key={index} className="events__list-item">
                      <ContentContainer content={caseItem} direction="row" />
                    </GridCol>
                  ))
                }
              </GridCol>
            </GridRow>
          </Grid>
        }
        {
          data.projects &&
          <Grid gap={10} noPadding={false}>
            <Devider margin={10} />
            <a anchor='projecten'></a>
            <H1 type="section">{data.projects.title}</H1>
            <GridRow direction="row" isWrapped={true}>
              {
                data.projects.items.map((item, index) => {
                  return (
                    <GridCol colSize={6} key={`research-item-${index}`}>
                      <ContentContainer content={item} />
                    </GridCol>
                  )
                })
              }
            </GridRow>
            <GridRow direction="row">
              <GridCol colSize={12}>
                <GridRow direction="row" isWrapped={true} isTile={true} isGrow={false} >
                  {
                    data.projects.rest.items.map((caseItem, index) => (
                      <GridCol colSize={4} key={index}>
                        <ContentContainer content={caseItem} isTileItem={true} />
                      </GridCol>
                    ))
                  }
                </GridRow>
              </GridCol>
            </GridRow>
          </Grid>
        }
        {
          data.organisatie && data.organisatie.bestuur &&
          <Grid gap={10} noPadding={false}>
            <Devider margin={10} />
            <GridRow direction="row">
              <GridCol colSize={8} colOffsetSize={9} direction="column" className="events__list events__list--square-thumbs events__list--personas">
                <H1 type="section">{data.organisatie.bestuur.title}</H1>
                <GridRow direction="row" isWrapped={true}>
                  {
                    data.organisatie.bestuur.items.map((caseItem, index) => (
                      <GridCol key={index} colSize={6} className="events__list-item">
                        <ContentContainer content={caseItem} direction="row" />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <H1 type="section">{data.organisatie.rvt.title}</H1>
                <GridRow direction="row" isWrapped={true}>
                  {
                    data.organisatie.rvt.items.map((caseItem, index) => (
                      <GridCol key={index} colSize={6} className="events__list-item">
                        <ContentContainer content={caseItem} direction="row" />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <H1 type="section">{data.organisatie.rva.title}</H1>
                <GridRow direction="row" isWrapped={true}>
                  {
                    data.organisatie.rva.items.map((caseItem, index) => (
                      <GridCol key={index} colSize={6} className="events__list-item">
                        <ContentContainer content={caseItem} direction="row" />
                      </GridCol>
                    ))
                  }
                </GridRow>
                <GridRow direction="row">
                  <GridCol>
                    <ContentContainer content={{
                      "links": [
                        {
                          "label": "Organogram Stichting Cosmicus",
                          "icon": "fingerprint",
                          "href": "/assets/images/organogram-bestuur-rest.png",
                          "target": "_blank"
                        }
                      ]
                    }} />
                  </GridCol>
                </GridRow>
              </GridCol>
              <GridCol colSize={4} colOffsetSize={12} isFlex={false}>
                <ContentWrapper isSticky={true} className="testimonials__wrapper">
                  <H2 type="section">{data.testimonials.title}</H2>
                  <Grid noPadding={true} offset={false}>
                    <GridRow direction="row">
                      <GridCol>
                        {
                          data.testimonials && !!data.testimonials.items && !!data.testimonials.items.length &&
                          <Carousel content={data.testimonials.items} activeIndex={0} />
                        }
                      </GridCol>
                    </GridRow>
                  </Grid>
                </ContentWrapper>
              </GridCol>
            </GridRow>
          </Grid>
        }

        <Grid gap={10} noPadding={true} offset={true}>
          <Devider margin={10} />
          <GridRow direction="row">
            <a anchor='clients'></a>
            <GridCol colSize={8} colOffsetSize={9}>
              <Grid gap={10} noPadding={true} className="section--clients">
                <H1 type="section">{data.casesContent.clients.title}</H1>
                <H3 type="section">{data.casesContent.clients.title2}</H3>
                <GridRow direction="row" isWrapped={true}>
                  {
                    data.casesContent.clients.items.map((caseItem, index) => (
                      <GridCol colSize={4} key={`clients-key-${index}`}>
                        <ContentContainer content={caseItem} isImageNoMargin={true} />
                      </GridCol>
                    ))
                  }
                </GridRow>
              </Grid>
            </GridCol>
            <GridCol colSize={4} colOffsetSize={12} isFlex={false}>
              <a anchor="contact"></a>
              <ContentWrapper isSticky={true}>
                <Grid noPadding={true} offset={false}>
                  <GridRow direction="row">
                    <GridCol>
                      <ContentWrapper className="content__container--light-background">
                        {/* <H2>{'ANBI'}</H2> */}
                        <GridRow direction="row">
                          <GridCol direction="column" className="events__list events__list--square-thumbs">
                            <GridCol className="events__list-item">
                              <ContentContainer content={getCurrentPageProps(data.anbi, '*')} direction="row" />
                            </GridCol>
                          </GridCol>
                        </GridRow>
                        <GridRow direction="row">
                          <GridCol direction="column" className="events__list events__list--square-thumbs">
                            <GridCol className="events__list-item">
                              <ContentContainer content={getCurrentPageProps(data.blikopwerk, '*')} direction="row" />
                            </GridCol>
                          </GridCol>
                        </GridRow>
                        <GridRow direction="row">
                          <GridCol direction="column" className="events__list events__list--square-thumbs">
                            <GridCol className="events__list-item">
                              <ContentContainer content={getCurrentPageProps(data.boip, '*')} direction="row" />
                            </GridCol>
                          </GridCol>
                        </GridRow>
                        <DefinitionList prefixed={true} content={getCurrentPageProps(data.anbi, '/list')} className="definition-list--dark" />
                        <GridRow direction="row">
                          <GridCol>
                            <ContentContainer content={getCurrentPageProps(data.anbi, '/main')} />
                          </GridCol>
                        </GridRow>
                        <DefinitionList prefixed={true} content={getCurrentPageProps(data.anbi, '/actions')} className="definition-list--dark" />
                      </ContentWrapper>
                    </GridCol>
                  </GridRow>
                  <GridRow>
                    <GridCol>
                      <ContentWrapper theme="dark">
                        <H2>{'Contact Info'}</H2>
                        <Address content={getCurrentPageProps(data.contactInfo, '*')} className="address" />
                        <Spacer spacing={6} />
                        <DefinitionList prefixed={true} content={getCurrentPageProps(data.contactInfo, '*')} className="definition-list--dark" />
                      </ContentWrapper>
                    </GridCol>
                  </GridRow>
                </Grid>
              </ContentWrapper>
            </GridCol>
          </GridRow>
        </Grid>
        <Grid gap={10} noPadding={true} offset={true}>
          <Devider margin={10} />
          <GridRow direction="row">
            <GridCol colSize={10}>
              <Footer title={data.footerTitle} navLinks={{ items: data.footerNavLinks.items }} />
            </GridCol>
            <GridCol colSize={2} align="right">
              <Footer navLinks={{ ctas: data.footerNavLinks.ctas }} />
            </GridCol>
          </GridRow>
        </Grid>
        <Spacer spacing={20}>
          <div className="logo-icon__wrapper">
            <div className="logo-icon logo-icon--large logo-icon--bottom" />
          </div>
        </Spacer>
        <CookiesTooltip>
          <ContentContainer content={data.cookiesConsent} />
        </CookiesTooltip>
      </HOC>
    ) : (
      <>
        No Data
      </>
    );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
