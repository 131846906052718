import React, { Fragment } from 'react';

const Address = ({content, children, ...props}) => {
  return (
    <>
      {
        content.addresses && content.addresses.length > 0 && content.addresses.map((address, index)=>(
          <address {...props} key={`addresses-listing-${index}`}>
            <strong>{address.label}</strong>
            {
              address && address.lines.map((line, index)=>(
                <Fragment key={`address-line-${index}`}>
                  {line}{'\n'}
                </Fragment>
              ))
            }
          </address>
        ))
      }
    </>
    
  );
};

export default Address;