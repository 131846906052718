export const setInitState = (defaultData, setState) => {
  setState(prevState => ({
    ...prevState,
    ...defaultData
  }));
};

export const updateState = (newStateData, setState) => setState(prevState => {
  return {
    ...prevState,
    ...newStateData
  };
});