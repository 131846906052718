import React from 'react';
import { Link } from 'gatsby';

import { isBrowser } from '../../../utils';

import Image from '../atoms/image';
import Navigation from '../molecules/navigation';

const mainNavigationClass = 'main-navigation';

export const mainNavigationScrollHandler = (e) => {
  if(!isBrowser()) return;

  if(!!document.querySelector(`.${mainNavigationClass}`)){
    if(window.scrollY > 0){
      return document.querySelector(`.${mainNavigationClass}`).classList.add(`${mainNavigationClass}--scrolled`);
    }
    document.querySelector(`.${mainNavigationClass}`).classList.remove(`${mainNavigationClass}--scrolled`);
  }
};

const MainNavigation = ({ navLinks, pageType }) => (
  <div className={`${mainNavigationClass} ${pageType ? ` is-${pageType}-page` : ''}`}>
    <div className={`${mainNavigationClass}__wrapper`}>
      <Link to={'/'} className="logo__link">
        <Image src="logo.png" alt="test" className="logo__wrapper" />
      </Link>
      {navLinks && <Navigation links={navLinks} />}
    </div>
  </div>
);

export default MainNavigation;
