import React, { useLayoutEffect, useState } from 'react';
import { isBrowser } from '../../../utils';

export default ({ children, className, ...props }) => {
  const [isConsent, setIsConsent] = useState(null);

  useLayoutEffect(() => {
    if (isBrowser()) {
      const closeBtn = document.querySelector('.cookies-consent .link[icon="close"]');
      !!closeBtn && closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        window.localStorage.setItem('cookiesConsent', JSON.stringify({ isOk: true }));
        setIsConsent(true);
      });
    }
  }, []);

  if (isBrowser()) {
    try {
      const { isOk } = JSON.parse(window.localStorage.getItem('cookiesConsent') || '{ isOk: false }');
      setIsConsent(isOk);
    } catch (error) {}
  }

  return (
    <>
    {
      !isConsent && 
      <div className={`cookies-consent ${className || ''}`} {...props}>
        {children}
      </div>
    }
    </>
  );
};
