
import * as InputComps from '../../components/generic/atoms/input';

export const mapComponent = (type, props) => ({ Component: InputComps[type], CompProps: props });

export const getComponent = ({ type, ...props }) => {
  return type &&
    type === 'text' ? { ...mapComponent('InputText', props) }
    : type === 'textarea' ? { ...mapComponent('InputTextarea', props) }
      : type === 'radio' ? { ...mapComponent('InputRadio', props) }
        : type === 'checkbox' ? { ...mapComponent('InputCheckbox', props) }
          : type === 'select' ? { ...mapComponent('InputSelect', props) }
            : {};
};

export const getFormFields = (fields = []) => {
  const keys = {};
  fields.map(field => keys[field.name] = field.value);
  return keys;
};

